import algoliasearch from "algoliasearch/lite";
import { SearchClient } from "algoliasearch/dist/algoliasearch-lite";
import { createInMemoryCache } from "@algolia/cache-in-memory";
import {
  MultipleQueriesQuery,
  MultipleQueriesResponse,
} from "@algolia/client-search";
import sentry from "lib/sentry";
import { SearchConfiguration } from "src/types/graphql.d";
import { isClient } from "src/utils/isClient";

interface Props {
  search: (
    queries: MultipleQueriesQuery[]
  ) => Promise<MultipleQueriesResponse<unknown> | undefined | null>;
  clearCache: () => null | Readonly<Promise<void>>;
}

export interface SearchConfigurationProps {
  data: { searchConfigurationsWeb: SearchConfiguration };
  errors: { message: string }[];
}

const fetcher = <T>(url: string): Promise<T> =>
  fetch(url).then((res) => res.json() as T);

const createAlgoliaClient = async () => {
  let client: SearchClient | null = null;
  const { captureException } = sentry();
  try {
    const {
      data: { searchConfigurationsWeb },
      errors,
    } = await fetcher<SearchConfigurationProps>("/api/search/config");
    if (errors) {
      captureException(errors[0]);
    }

    client = algoliasearch(
      searchConfigurationsWeb?.app_id,
      searchConfigurationsWeb?.search_api_key,
      {
        responsesCache: createInMemoryCache(),
        requestsCache: createInMemoryCache({ serializable: false }),
      }
    );
    return client;
  } catch (err) {
    captureException(err);
    return null;
  }
};

function AlgoliaClientFactory(): {
  getClient: () => Promise<SearchClient | null>;
  clearClient: () => void;
  hasAttempts: () => boolean;
} {
  let client: SearchClient | null = null;
  let attempts = 0;

  const hasAttempts = () => {
    return attempts < 3;
  };

  const getAlgoliaClient = async (): Promise<SearchClient | null> => {
    let newClient: SearchClient | null = null;

    while (!newClient && hasAttempts()) {
      newClient = await createAlgoliaClient();
      attempts++;
    }
    return newClient;
  };

  const getClient = async () => {
    if (!client) {
      client = await getAlgoliaClient();
    }
    return client;
  };

  const clearClient = () => {
    client = null;
  };

  return {
    getClient,
    clearClient,
    hasAttempts,
  };
}

const AlgoliaFilter = AlgoliaClientFactory();
export { AlgoliaFilter };

function Algolia(): Props {
  let client: SearchClient | null = null;

  const createClient = async () => {
    client = await createAlgoliaClient();
  };

  const search = (queries: MultipleQueriesQuery[]) => {
    let attempts = 0;
    const handleSearch = async <T>(): Promise<
      MultipleQueriesResponse<T> | undefined | null
    > => {
      if (!client) {
        await createClient();
      }
      try {
        return await client?.search(queries);
      } catch (err) {
        const { captureException } = sentry();
        captureException(err);
        if (attempts++ < 3) {
          await createClient();
          return await handleSearch();
        }
      }
      return null;
    };
    return handleSearch();
  };

  if (isClient) {
    setTimeout(() => void createClient(), 3000);
  }

  return {
    search,
    clearCache: () => client && client.clearCache(),
  };
}

export default Algolia();
